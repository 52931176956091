import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify"
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  Greetings,
  SignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../../../components/CustomSignUp"
import {
  createProject,
  updateProject,
  createRoom,
} from "../../../../graphql/mutations"

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import awsExports from "../../../../aws-exports"
import { Hub } from "aws-amplify"
import { listMainTags, listProjects } from "../../../../graphql/queries"
import {
  deleteProjectTag,
  createProjectTag,
} from "../../../../graphql/mutations"
import AdminMenu from "../../../../components/AdminMenu"

Amplify.configure(awsExports)

const SignupLink = props => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange("signUp")}>
          Sign Up here
        </button>
      </div>
    )}
  </div>
)

const TagProjectsPage = () => {
  const [user, setUser] = useState(null)
  const [userPool, setUserPool] = useState(null)

  useEffect(() => {
    if (!user) {
      getUser()
    }
  })

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log("user: ", user)
      //check for user pool group
      console.log(
        "User Pool Group: ",
        user.signInUserSession.accessToken.payload["cognito:groups"]
      )
      // console.log("attributes: ", user.attributes)
      console.log(
        "Is Admin: ",
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "Admins"
        )
      )
      setUser(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in")
        getUser()
        break
      case "signUp":
        console.log("user signed up")
        break
      case "signOut":
        console.log("user signed out")
        break
      case "signIn_failure":
        console.log("user sign in failed")
        break
      case "configured":
        console.log("the Auth module is configured")
    }
  })

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      console.log("running handle auth change")
      getUser()
    }
  }

  const MainTagList = props => {
    const [mainTags, setMainTags] = useState([])

    useEffect(() => {
      fetchMainTags()
    }, [])

    const ProjectTagComponent = props => {
      const [checked, setChecked] = useState(false)
      const [foundTag, setFoundTag] = useState("")
      const [disabled, setDisabled] = useState(false)

      useEffect(() => {
        console.log("props projects wtf: ", props.project)

        console.log("props projects dude: ", props.project.tags.items)
        var foundTag = ""
        // var checked = false
        props.project.tags.items.map(projectTag => {
          if (projectTag.tag.id == props.tag.id) {
            console.log("IT IS TRUE!")
            setChecked(true)
            // checked = true
            setFoundTag(projectTag.id)
            {
              /* setChecked(checked.map(tag.id, checked)) */
            }
          }
        })
        console.log("True Tag: ", checked)
      }, [])

      {
        return (
          <li key={props.tag.name}>
            <div>
              {props.tag.name}
              <div>
                <input
                  type="checkbox"
                  disabled={disabled}
                  onClick={async () => {
                    if (checked == true) {
                      // console.log("link id: ", foundTag)
                      setDisabled(true)
                      await deleteThisProjectTag(foundTag)
                      setChecked(false)
                      setDisabled(false)
                    } else {
                      setDisabled(true)
                      await createThisProjectTag(props.project.id, props.tag.id)
                      setChecked(true)
                      setDisabled(false)
                    }
                  }}
                  checked={checked ? true : false}
                ></input>
              </div>
            </div>
          </li>
        )
      }
    }

    async function deleteThisProjectTag(id) {
      try {
        const projectTag = { id: id }
        console.log("projectTag: ", projectTag)
        const deletedProjectTag = await API.graphql(
          graphqlOperation(deleteProjectTag, { input: projectTag })
        )
        console.log("deleted projectTag: ", deletedProjectTag)
        // const newprojectTagState = projectTags.filter(projectTag => projectTag.)
        // fetchMainTags()
        // useForceUpdate();
      } catch (err) {
        alert("Something went wrong: ", err)
        console.log("error deleting projectTag:", err)
      }
    }

    async function createThisProjectTag(projectId, tagId) {
      try {
        const projectTag = { projectID: projectId, tagID: tagId }
        console.log("projectTag: ", projectTag)
        const createdProjectTag = await API.graphql(
          graphqlOperation(createProjectTag, { input: projectTag })
        )
        console.log(" projectTag: ", createdProjectTag)
        // const newprojectTagState = projectTags.filter(projectTag => projectTag.)
        // fetchMainTags()
        // useForceUpdate()
      } catch (err) {
        alert("Something went wrong: ", err)
        console.log("error creating projectTag:", err)
      }
    }

    async function fetchMainTags() {
      try {
        const mainTagsData = await API.graphql(graphqlOperation(listMainTags))
        console.log("main tags data: ", mainTagsData)
        const mainTags = mainTagsData.data.listMainTags.items
        setMainTags(mainTags)
        console.log("mainTags: ", mainTags)
      } catch (err) {
        console.log("error fetching mainTags: ", err)
      }
    }

    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)

    if (mainTags.length > 0) {
      return (
        <section>
          {mainTags.map((mainTag, index) => (
            <div
              key={mainTag.id ? mainTag.id : index}
              style={{
                border: "1px solid black",
                margin: "1rem",
              }}
            >
              <div>
                <h3 key="title">{mainTag.name}</h3>
              </div>
              <ul>
                {mainTag.tags.items &&
                  mainTag.tags.items.map(tag => (
                    <ProjectTagComponent tag={tag} project={props.project} />
                  ))}
              </ul>
            </div>
          ))}
        </section>
      )
    } else {
      return <div>No tags</div>
    }
  }

  const ProjectList = props => {
    const [projects, setProjects] = useState([])
    useEffect(() => {
      fetchProjects()
    }, [])

    async function fetchProjects() {
      try {
        const projectData = await API.graphql(graphqlOperation(listProjects, {limit: 1000}))
        const projects = projectData.data.listProjects.items
        setProjects(projects)
        console.log("projects: ", projects)
      } catch (err) {
        console.log("error fetching projects: ", err)
      }
    }

    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)
    if (
      props.authState === "signedIn" &&
      user &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Admins"
      )
    ) {
      if (projects.length > 0) {
        return (
          <div>
            <AdminMenu />
            <section>
              {projects.map((project, index) => (
                <div
                  key={project.id ? project.id : index}
                  style={{
                    border: "1px solid black",
                    margin: "1rem",
                  }}
                >
                  <ul>
                  <li key="id">Title: {project.id}</li>

                    <li key="title">Title: {project.title}</li>
                    <li key="abstract">Abstract: {project.description}</li>
                  </ul>
                  <MainTagList project={project} />
                </div>
              ))}
            </section>
          </div>
        )
      } else {
        return <Link to="/partner/submit">Submit a project</Link>
      }
    } else return <div>You don't have permission to view this page. </div>
  }

  return (
    <section>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
        >
          <ProjectList />

          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Admin Dashboard | Tag Projects" />
      <Link to="/admin">Go back to Admin dashboard</Link>
    </section>
  )
}

export default TagProjectsPage
